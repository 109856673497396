import dynamic from 'next/dynamic';
import { Input, message, Tabs } from 'antd';
import { Search as SearchIcon, Translate } from '@icon-park/react';
import styles from './styles/Banner.module.scss';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Base64 } from "js-base64";
// import pinyin from "pinyin";
import { wallpaperItem, } from '../constants/api'
import { http } from '../utils/api'
import { State } from '../@types/reducer';
import { useSelector } from 'react-redux';
// import IndexWallpaper from './IndexWallpaper';
// import IndexSearch from './IndexSearch';
const IndexSearch = dynamic(
    import('./IndexSearch'),
    { ssr: false }
)
const IndexWallpaper = dynamic(
    import('./IndexWallpaper'),
    { ssr: false }
)
import { SearchList, WallpaperItem } from '../@types';
import { banner } from '../constants/heads';
import NoSsr from './NoSsr';

const { Search } = Input;
const { TabPane } = Tabs;

const Banner: NextPage<{ searchList: SearchList[] }> = (props) => {
    // const { baseUrl, isBanner, userInfo,responsive} = useSelector((state: State) => state)
    const { isXs, isSm, isMd, isXxl } = useSelector((state: State) => state.responsive)
    const [searchList, setSearchList] = useState(props.searchList)
    const [index, setIndex] = useState("0")
    const [active, setActive] = useState("0")
    const [bg, setBg] = useState<WallpaperItem>({ _id: '', type: '', url: '', urlMin: '', style: [] })
    const [item, setItem] = useState(searchList[Number(index)].list[Number(active)] || searchList[0].list[0])
    const router = useRouter()
    const callback = (key: string) => {
        if (Number(key) < searchList.length) {
            setIndex(key)
            setActive(searchList[Number(key)].active)
            localStorage.setItem('search-index', key)
            localStorage.removeItem('search-active')
        }
    }

    const AcitveCallback = (key: string) => {
        if (Number(key) < searchList[Number(index)].list.length) {
            searchList[Number(index)].active = key
            setSearchList(searchList)
            setActive(key)
            localStorage.setItem('search-active', key)
        }
    }

    // const getPinyin = (text: string) => {
    //     let result: any[] = [];
    //     pinyin(text, { style: pinyin.STYLE_NORMAL, segment: false }).map((item: any[]) => { result.push(item.join("")); });
    //     return result.join("");
    // }

    const internal = (text: string) => {
        const tempwindow: any = window.open("skip"); // 先打开页面
        tempwindow.location = `/key/${text}`
    }

    const onSearch = (value: any) => {

        const text = value.constructor === String ? String(value) : value.target.defaultValue

        if (text.length < 1) {
            message.error('请输入关键字');
            return
        }
        console.log(item)
        if (item.href.indexOf('/key/#text#') !== -1) {
            internal(text)
            return
        }

        let link = `/api/link?href=${Base64.encode(item.href)}&text=${text}`
        if (item.pinyin) {
            link = `${link}&pinyin=true`
        }
        if (item.translation) {
            link = `${link}&translation=true`
        }

        window.open(link, "_blank");

    }

    const translateFun = () => {
        const k = Number(active)
        const i = Number(index)
        searchList[i].list[k].translation = !searchList[i].list[k].translation
        setSearchList(searchList.slice())
    }


    useEffect(() => {
        setItem(searchList[Number(index)].list[Number(active)])
    }, [index, active]);

    useEffect(() => {
        const searchIndex = localStorage.getItem('search-index')
        const searchActive = localStorage.getItem('search-active')
        if (searchIndex) {
            callback(searchIndex)
            if (searchActive) AcitveCallback(searchActive)
        }
        http(wallpaperItem).then((res: any) => { //设置默认壁纸
            if (res.code == 200) {
                setBg(res.data)
            }
        })
    }, []);

    return (
        <div className={styles.banner}>
            {bg.type == 'bing' || bg.type == 'user' ? <div className="background"><img src={bg.url} /></div> : ''}
            {bg.type == 'gradual' && <div className={styles.effect} style={{ ...bg.style[0], height: '100%' }}></div>}
            <div className="search">
                <h2>{banner}</h2>
                <NoSsr>
                    <Tabs centered defaultActiveKey={index} activeKey={index} size={isXs ? 'small' : 'middle'} onChange={callback}>
                        {searchList.map((item, index) => (
                            <TabPane tab={item.title} key={String(index)}></TabPane>
                        ))}
                    </Tabs>
                    <Search
                        placeholder={item.text}
                        enterButton={<SearchIcon style={{ padding: '0 15px' }} strokeWidth={3} theme="outline" size="22" fill="#fff" strokeLinecap="butt" />}
                        size="large"
                        onPressEnter={onSearch}
                        onSearch={onSearch}
                        suffix={
                            !item.hideTranslate && <Translate onClick={translateFun} theme="outline" size="20" fill={item.translation ? "#000" : "#999"} strokeWidth={3} strokeLinecap="butt" />
                        }
                    />
                    <Tabs centered defaultActiveKey={active} activeKey={active} tabPosition='bottom' size={isXs ? 'small' : 'middle'} onChange={AcitveCallback}>
                        {searchList[Number(index)].list.map((item, index: number) => (
                            <TabPane tab={item.title} key={String(index)}></TabPane>
                        ))}
                    </Tabs>
                </NoSsr>
            </div>
            {props.children}
            <NoSsr>
                <IndexWallpaper setCallback={setBg} active={bg} />
                {/* <IndexSearch setCallback={setBg} active={bg} /> */}
            </NoSsr>
        </div>
    )
}
export default Banner